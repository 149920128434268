@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap);

.closedchallenges-card {
    padding-right: 50px;
}

.closedchallenges-card-item {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 311px;
    height: 600px;
    background: var(--default-background);
    border: 1px solid var(--text-color-200);
    box-shadow: 0px 2px 20px var(--shadow-shade-1);
    border-radius: 0px 0px 8px 8px;
}

.closedchallenges-card-item img {
    width: 100%;
    height: 168px;
}

.logo-circle {
    position: absolute;
    width: 106px;
    height: 106px;
    right: 15px;
    top: 115px;
    border-radius: 100%;
    filter: drop-shadow(0px 2px 20px var(--shadow-shade-1));
    background: var(--default-background);
    align-items: center;
    display: flex;
    justify-content: center;
}

.logo-circle img {
    width: 75%;
    height: 75%;
}

.closedchallenges-card-body {
    text-align: left;
    padding: 20px;
}

.closedchallenges-card-item h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: var(--primary-color-main);
    margin: 0;
    text-overflow: ellipsis;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
}

.logo-circle img {
    border-radius: 30%;
}

.subtitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    color: var(--secondary-color-main);
}

.description {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: var(--default-black);
    margin: 0;
    height: 200px;
    overflow-y: scroll;
}

.challenges-link {
    text-decoration: none;
}

.closedchallenges-card-link {
    padding-bottom: 20px;
    padding-left: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: var(--primary-color-main);
        margin: 0 auto;
    }

    p:hover {
        color: var(--secondary-color-main);
    }

    img {
        width: 12px;
        height: 12px;
        padding-left: 5px;
    }
}

@media screen and (max-width: 900px) {
    .closedchallenges-card {
        padding: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0px auto;

        .closedchallenges-card-item {
            width: 90%;
            margin: 10px 0px;
        }
    }
}
