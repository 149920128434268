@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;600;700;900&display=swap);

.company-challenges {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  font-family: Roboto;

  .open-hackatons-title {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    text-align: center;
    color: var(--success-color-dark);
    text-transform: uppercase;
  }
}

.tabs {
  margin-top: 150px;
}

.tabs img {
  width: 24px;
  height: 24px;
  padding-left: 5px;
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 50px;
  margin: 0 auto;
  max-width: 1100px;
}

.cards h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: var(--success-color-dark);
}

/*body::-webkit-scrollbar {
    width: 8px;
}

body::-webkit-scrollbar-track {
    background: transparent;
}

body::-webkit-scrollbar-thumb {
    background-color: var(--primary-color-main);
    border-radius: 15px;
} */
