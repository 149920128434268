@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap%27);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap);

.banner {
    display: block;
}


.desafios-banner {
    height: 90vh;
    background: transparent url('../../../public/images/Banner/principal_banner.png') 0% 0% no-repeat padding-box;
    opacity: 1;
    background-size: cover;
    background-position: center;
    position: relative;
}

.desafios-banner .square {
    position: absolute;
    top: 65px;
    right: 318px;
}

.desafios-banner .square img{
    width: 510px;
}

.desafios-banner .text-label {
    position: absolute;
    top: 130px;
    left: 200px;
}

.desafios-banner .text-label p {
    font: normal normal normal 45px Space Grotesk;
    color: var(--default-white);
    text-align: left;
}

.desafios-banner .text-label span {
    color: var(--secondary-color-main);
}

.open-challenges-button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 200px;
    height: 40px;
    background: var(--default-white);
    border: 1px solid var(--secondary-color-background);
    box-shadow: 0px 1px 2px var(--shadow-shade-05);
    border-radius: 5px;
    flex: none;
    order: 1;
    flex-grow: 0;
    position: absolute;
    left: 200px;
    top: 440px;
    color: var(--primary-color-main);
}

.open-challenges-button:hover {
    box-shadow: inset 0px 0px 400px 110px var(--shadow-shade-2);
}

.link {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 16px;
    color: var(--primary-color-main);
    text-decoration: none
}

.link:hover {
    color: var(--secondary-color-main);
}

@media screen and (max-width: 600px){

    .desafios-banner .text-label {
        top: 290px;
        left: 45px;
    }

    .desafios-banner .text-label p {
        font-size: 36px;
    }

    .desafios-banner .square{
        top: 200px;
        right: 50px;
    }

    .desafios-banner .square img{
        width: 310px;
    }
    
}
