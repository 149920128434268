.field {
  width: 65%;
  position: relative;
  margin: 0 auto;

  input {
    background-color: var(--default-white);
    opacity: 1;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--text-color-400);
    background-clip: padding-box;
    border: 1px solid var(--text-color-200);
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      outline: none;
    }
  }

  label {
    font-size: 17px;
    color: var(--text-color-400);
    position: absolute;
    top: 5px;
    left: 10px;
    transition: all ease-in-out 400ms;
    vertical-align: middle;
    transform-origin: 0%;
    transition: all ease-in-out 400ms;
    pointer-events: none;
    background-color: var(--default-white);

    &.labelValueOn {
      transform: scale(0.7) translateY(-1.5rem);
    }
  }

  .input-message {
    font-size: 0.875em;
    color: var(--text-color-400);
    margin-top: 0.25rem;

    &.error {
      color: darkred;
    }
  }
}

.field {
  input:focus + label {
    color: var(--text-color-400);
    transform: scale(0.7) translateY(-1.5rem);
  }
}

@media screen and (max-width: 990px) {
  .field {
    width: 100%;
  }
}
