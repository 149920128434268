@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap);

.about-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
    gap: 50px;
    margin: 0 auto;
    max-width: 1100px;
    padding-top: 200px;
}

.about-area img {
    width: 372px;
    height: 271px;
}

.about-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    gap: 17px;
    text-align: left;
    padding-right: 50px;
}

.about-text h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    color: var(--primary-color-main);
}

.about-text p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: var(--text-color-700);
}

@media screen and (max-width: 900px) {
    .about-area {
        width: 100%;
        padding: 50px 20px;
        flex-direction: column-reverse;

        .about-text {
            padding: 0px;

            h1 {
                font-size: 32px;
            }
        }

        img {
            max-width: 200px;
            height: 170px;
        }
    }
}
