@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap);

.accordion-form {
    padding-top: 50px;
    padding-bottom: 100px;
}

.accordion-form h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 26px;
    color: var(--primary-color-main);
    margin: 30px;
}

.challenge-register {
    padding-bottom: 50px;
    font-family: 'Roboto';
    font-style: normal;
    text-align: left;
    
}

.open-challenges-register h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 26px;
    color: var(--primary-color-main);
    margin: 30px;
}

.other-challenges-register {
    text-align: left;
}

.company-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 26px;
    color: var(--primary-color-main);
    margin: 30px;
    text-align: left;
}