.home {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background:  
  linear-gradient(
  180deg,
  rgba(209, 202, 218, 0.11) 3.3%,
  rgba(174, 135, 220, 0.2) 47.05%,
  rgba(170, 129, 218, 0.2) 50.17%,
  rgba(251, 248, 255, 0.2) 100%);
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color-main);
  border-radius: 15px;
}
