@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);

.footer {
  width: 100%;
  background: var(--primary-color-dark);
}

.open-logo img {
  padding-top: 20px;
}

.back-home-link {
  padding-top: 25px;
  padding-bottom: 20px;
  text-align: left;
  padding-left: 60px;
  width: 330px;
}

.back-home-link a {
  font-family: "Roboto";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--default-white);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.back-home-link a:hover {
  font-weight: 600;
}

.back-home-link img {
  width: 35px;
  height: 35px;
  padding: 0px 8px;
}

.footer-description {
  justify-content: center;
}

.footer-description p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--default-white);
  margin: 20px;
}

.footer-description a {
  text-decoration: none;
  font-weight: 600;
  color: var(--default-white);
}

@media screen and (max-width: 900px) {
  .footer-description p,
  a {
    font-size: 12px;
  }
}
