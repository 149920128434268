.wrapper {
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1100px;
  padding-top: 80px;
  padding-bottom: 50px;
  margin: 0 auto;
}

@media screen and (max-width: 900px) {
  .wrapper {
    padding-left: 20px;
  }
}
