.container-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 1150px) {
        flex-direction: column;
        justify-content: center;
        width: 50%;
        padding: 4rem 0px !important;
    }

    @media (max-width: 768px) {
        width: 100%;
        align-items: center !important;
    }
}
