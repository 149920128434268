.token-modal-body {
  text-align: center;

  h1 {
    font-size: 22px;
  }
}

.token-form-row {
  justify-content: center;
  margin-top: 45px;
}

.button {
  margin: 20px 0px;
}

@media screen and (max-width: 900px) {
  .modal {
    width: 100vw !important;
  }
}
