@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap);

.openchallenges-card {
  padding-right: 50px;
}

.openchallenges-card-item {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 311px;
  height: 600px;
  background: var(--default-background);
  border: 1px solid var(--text-color-200);
  box-shadow: 0px 2px 20px var(--shadow-shade-1);
  border-radius: 0px 0px 8px 8px;
}

.openchallenges-card-item img {
  width: 100%;
  height: 168px;
}

.openchallenges-card-body {
  text-align: left;
  padding: 20px;
}

.openchallenges-card-item h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: var(--primary-color-main);
  margin: 0;
  text-overflow: ellipsis;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
}

.logo-circle img {
  border-radius: 30%;
}

.description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: var(--default-black);
  margin: 0;
  height: 200px;
  overflow-y: scroll;
}

.openchallenges-card-button {
  padding-bottom: 20px;

  a {
    text-decoration: none;
  }
}

.openchallenges-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 18px;
  gap: 10px;
  background: var(--primary-color-main);
  box-shadow: 0px 1px 2px var(--shadow-shade-05);
  border-radius: 5px;
  color: var(--default-white);
  min-width: 130px;
  height: 35px;
  text-transform: uppercase;
}

.openchallenges-button:hover {
  box-shadow: inset 0px 0px 400px 110px var(--shadow-shade-3);
  color: var(--secondary-color-main);
}

.openchallenges-button p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--default-white);
}

@media screen and (max-width: 900px) {
  .openchallenges-card {
    padding-right: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0px auto;

    .openchallenges-card-item {
      width: 90%;
      margin: 10px 0px;
    }
  }
}
