button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 26px;
  gap: 10px;
  border: 1px solid var(--secondary-color-background);
  box-shadow: 0px 1px 2px var(--shadow-shade-05);
  font-family: "Roboto";
  font-style: normal;
  line-height: 21px;
  min-width: fit-content;
}

button:hover {
  box-shadow: inset 0px 0px 400px 110px var(--shadow-shade-2);
}
