@keyframes slide-in {
  0% {
    right: -200px;
    opacity: 0;
  }
  100% {
    right: 10px;
    opacity: 1;
  }
}

@keyframes slide-out {
  0% {
    right: 10px;
    opacity: 1;
  }
  100% {
    right: -200px;
    opacity: 0;
  }
}

.alertComponent {
  z-index: 999;
  position: fixed;
  bottom: 60px;
  right: 10px;
  width: 500px;
  padding: 16px;
  animation:
    slide-in 1s ease-out forwards,
    slide-out 1s ease-out forwards 5s;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 6px;
  gap: 7px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.success {
    background-color: var(--success-color-background);
    color: var(--success-color-main);
    border-left: 8px solid var(--success-color-main);
  }

  &.warning {
    background-color: var(--warning-color-background);
    color: var(--warning-color-main);
    border-left: 8px solid var(--warning-color-main);
  }

  &.error {
    background-color: var(--error-color-background);
    color: var(--error-color-main);
    border-left: 8px solid var(--error-color-main);
  }

  .alertContent {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 10px;

    h4 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: flex-end;
      margin: 4px 0px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: flex-end;
      margin: 0px;
    }
  }

  @media screen and (max-width: 1300px) {
    width: 80%;
  }

  @media screen and (max-width: 900px) {
    width: 80%;

    .alertContent {
      padding-left: 10px;

      h4 {
        font-size: 14px;
      }

      p {
        font-size: 12px;
      }
    }
  }

  .icon {
    cursor: pointer;
    color: var(--text-color-500);

    @media screen and (max-width: 700px) {
      width: 40px;
      position: absolute;
      top: 15px;
      right: 5px;
    }
  }
}
