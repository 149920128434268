.companies {
  padding-top: 100px;
}

.companies h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: var(--primary-color-main);
  text-transform: uppercase;
}

.companies-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 85px;
  gap: 50px;
  height: 202px;
  margin: 90px;
  justify-content: center;
}

.companies-card-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 40px;
  gap: 10px;
  background: var(--default-white);
  box-shadow: 0px 2px 20px var(--shadow-shade-1);
  border-radius: 5px;
  margin: 0px 30px;
}

.companies-card-item img {
  width: 112px;
  height: 86px;
}

.start-health {
  background: var(--text-color-600) !important;
}

@media screen and (max-width: 900px) {
  .companies-card {
    flex-direction: column;
    height: auto;
    margin: 0px;
    padding: 0px;
  }

  .companies-card-item {
    margin: 10px 0px;
  }

  .companies h1 {
    font-size: 24px;
  }
}
