@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;600;700;900&display=swap);

.about-company {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 90px 190px;
  gap: 20px;

  a {
    color: var(--text-color-600);
    font-weight: bold;
    text-decoration: none;
  }
}

.about-company h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  text-align: center;
  color: var(--success-color-dark);
  width: 100%;
  margin: 0;
}

.about-company p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text-color-600);
  margin: 0;
}

@media screen and (max-width: 900px) {
  .about-company {
    padding: 80px 25px 25px;
  }
}
