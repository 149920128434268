@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap);

.contact {
    width: 100%;
    position: relative;
    height: 707px;
    background: var(--primary-color-main);
}

.contact-title {
    padding-top: 50px;
}

.contact h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    margin: 0;
    color: var(--default-white);
}

.contact-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 195px;
    gap: 5px;
    height: 511px;
}

.contact-text {
    text-align: left;
    width: 80%;
}

.contact-text p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: var(--default-white);
}

.input-div {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.input-div label {
    text-align: left;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: var(--default-white);
    padding-bottom: 5px;
}

.input-div input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 533px;
    height: 44px;
    background: var(--default-white);
    border: 1px solid var(--text-color-100);
    border-radius: 6px;
}

.input-div textarea {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
    min-width: 533px;
    min-height: 140px;
    max-width: 533px;
    max-height: 140px;
    background: var(--default-white);
    border: 1px solid var(--text-color-100);
    border-radius: 6px;
}

.button-div {
    justify-content: center;
    display: flex;
}

.button-div button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 26px;
    gap: 10px;
    width: 109px;
    height: 49px;
    background: var(--default-white);
    border: 1px solid var(--secondary-color-background);
    box-shadow: 0px 1px 2px var(--shadow-shade-05);
    border-radius: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: var(--primary-color-dark);
}

.button-div button:hover {
    color: var(--secondary-color-main);
    box-shadow: inset 0px 0px 400px 110px --shadow-shade-2;
}

@media screen and (max-width: 1100px) {
    .contact {
        height: auto;
    }

    .contact-text {
        width: 100%;
    }

    .contact-area {
        padding: 10px 20px;
        height: auto;
    }

    .input-div {
        width: 100%;
    }

    .input-div input, .input-div textarea {
        min-width: 100%;
        width: 100%;
        max-width: 553px;
    }

    .container-column {
        width: 100% !important;
    }
}
