@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap);

.startup-form {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--primary-color-main);
  text-align: left;
}

.startup-form h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 26px;
  color: var(--primary-color-main);
  margin: 50px;
  text-align: center;
}

.button-div {
  width: 100%;
  margin: 50px 0px;
  display: flex;
  justify-content: center;
}

.error {
  position: absolute;
  color: darkred;
  font-size: 10px;
  margin-top: -4px;
  margin-left: 10px;
}

@media screen and (max-width: 900px) {
  .startup-form h1 {
    font-size: 42px;
    margin: 50px 20px;
    line-height: 35px;
  }

  .container .row {
    flex-direction: column;
    margin-bottom: 0px !important;
  }

  .container .col {
    padding: 12px 20px;
  }
}
