@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;900&display=swap);

.how-it-works {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
  gap: 50px;
  margin: 0 auto;
  max-width: 950px;
}

.how-it-works h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: var(--primary-color-main);
  text-transform: uppercase;
}

.card {
  margin: 20px;
}

.card-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 25px;
  gap: 30px;
  width: 294px;
  height: 317px;
  background: var(--primary-color-main);
  box-shadow: 0px 2px 20px var(--shadow-shade-1);
  border-radius: 8px;
}

.card-item:hover {
  background: var(--primary-color-main);
  box-shadow: 0px 2px 20px var(--primary-color-main);
  border-radius: 8px;
}

.card-item img {
  margin: 0;
  width: 45px;
  height: 46px;
}

.card-item .card-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 19px;
  text-align: center;
  color: var(--default-white);
  margin: 0;
}

.card-item p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--default-white);
  margin: 0;
}

@media screen and (max-width: 900px) {
  .card-item {
    width: auto;
  }
}
