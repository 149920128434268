:root {
    // Basic Color Palette
        --default-black: #000000;
        --default-white: #ffffff;
        --default-background: #fafdff;

    // Colors Style Primary
        --primary-color-dark: #28003f;
        --primary-color-main: #3d0060;
        --primary-color-light: #8e00e0;
        --primary-color-background: #fffffa;

    // Colors Style Secondary
        --secondary-color-deep: #aa81da;
        --secondary-color-dark: #8f079a;
        --secondary-color-main: #d93de7;
        --secondary-color-light: #f793ff;
        --secondary-color-background: #fff5ef;

    // Colors Style Complementary
        --complementary-color-dark: #1c66a5;
        --complementary-color-main: #377b8d;
        --complementary-color-light: #5ca3b3;
        --complementary-color-background: #f2fdff;

    // Colors Style Text
        --text-color-700: #1f1d1d;
        --text-color-600: #263238;
        --text-color-500: #37474f;
        --text-color-400: #455a64;
        --text-color-300: #78909c;
        --text-color-200: #cfd8dc;
        --text-color-100: #e8ecee;

    // Colors Style Warning
        --warning-color-deep: #EF7D00;
        --warning-color-dark: #eeab00;
        --warning-color-main: #fcc332;
        --warning-color-light: #ffd772;
        --warning-color-background: #fff8e8;

    // Colors Style Error
        --error-color-dark: #aa2929;
        --error-color-main: #eb5959;
        --error-color-light: #f97f7f;
        --error-color-background: #ffefef;

    // Colors Style Success
        --success-color-dark: #005024;
        --success-color-main: #006f38;
        --success-color-light: #009250;
        --success-color-background: #e1f3ea;

    // Colors Style Shadow Shades
        --shadow-shade-5: rgba(0, 0, 0, .5);
        --shadow-shade-4: rgba(0, 0, 0, .4);
        --shadow-shade-3: rgba(0, 0, 0, .3);
        --shadow-shade-2: rgba(0, 0, 0, .2);
        --shadow-shade-1: rgba(0, 0, 0, .1);
        --shadow-shade-05: rgba(0, 0, 0, .05);
}
