@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap);

.open-challenges {
  overflow-x: scroll;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 50px;
  margin: 0 auto;
  max-width: 1100px;
}

.open-challenges h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: var(--primary-color-main);
  text-transform: uppercase;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color-main);
  border-radius: 15px;
}
