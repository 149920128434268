@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap);

.navbar-background {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(61, 0, 96, 0.7);
  height: 100px;
  width: 100vw;
  z-index: 999;
}

.navbar-logo {
  position: absolute;
  left: 40px;
}

.navbar-logo img {
  width: 70px;
  height: 65px;
}

.menu-itens-desktop {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 40px;
  position: absolute;
  right: 20px;
  top: 25px;

  .menu-text {
    height: 19px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    flex: none;
    order: 0;
    flex-grow: 0;
    text-decoration: none;
    padding-left: 25px;
    color: var(--primary-color-background);
    text-transform: uppercase;
  }

  .menu-text:hover {
    color: var(--secondary-color-main);
  }

  .menu-text:active {
    color: var(--secondary-color-main);
  }
}

.menu-itens-mobile {
  display: none;
}

.menu-language {
  position: relative;
}

.menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-shrink: 0;

  width: 70px;
  height: 34px;
  border-radius: 56px;
  border: 2px solid #37474f;

  background-color: #fff;

  padding: 0;

  span {
    text-transform: uppercase;
    flex-shrink: 0;
  }

  svg {
    transition: transform 0.3s ease-in-out;

    &.menu-open {
      transform: rotate(180deg);
    }
  }
}
.menu-select {
  display: flex;
  flex-direction: column;

  width: 70px;

  position: absolute;
  top: 100%;

  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 101;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 28px;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

@media screen and (max-width: 900px) {
  .menu-itens-desktop {
    display: none;
  }

  .menu-itens-mobile {
    display: block;
    color: var(--text-color-100);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: absolute;
    height: 39px;
    width: 30px;
    right: 20px;
    top: 30px;
  }
}
