@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;600;700;900&display=swap);

.challenges-banner {
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100vh;
  object-fit: fill;
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: cover;
}

.banner-info {
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 30px;
  position: absolute;
  top: 250px;
  width: 100%;
  justify-content: center;
  display: flex;
}

.banner-info h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  text-align: center;
  color: var(--default-white);
  margin: 0;
}

.banner-info p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 30px;
  text-align: center;
  color: var(--default-white);
  margin: 0;
}

.challenges-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 40px;
  background: #ef7d00;
  box-shadow: 0px 1px 2px var(--shadow-shade-05);
  border-radius: 5px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 110px;
  text-transform: uppercase;
}

.challenges-button:hover {
  box-shadow: inset 0px 0px 400px 110px var(--shadow-shade-2);
}

.link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--default-background) !important;
  text-decoration: none;
}

.company-logo-circle {
  background: var(--default-background);
  width: 131px;
  height: 131px;
  border-radius: 50%;
  position: absolute;
  bottom: -60px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 2px 20px var(--shadow-shade-1));

  img {
    width: 100px;
  }
}

@media screen and (max-width: 900px) {
  .banner-info {
    width: 90%;
  }

  .company-logo-circle {
    width: 100px;
    height: 100px;
  }
}
