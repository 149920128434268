@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;600;700;900&display=swap);

.general-information {
  display: flex;
  flex-direction: column;
  padding: 30px 190px;
  gap: 20px;
  margin-top: 50px;
}

.general-information h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  text-align: center;
  color: var(--success-color-dark);
  text-transform: uppercase;
}

.who-participates {
  text-align: left;
}

.who-participates h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
  color: var(--success-color-dark);
  text-align: left;
}

.who-participates p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: var(--text-color-600);
}

.benefits {
  text-align: left;
}

.benefits h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
  color: var(--success-color-dark);
  text-align: left;
}

.benefits p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: var(--text-color-600);
}

.questions {
  text-align: left;
}

.questions p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: var(--text-color-600);
}

.notice {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: var(--text-color-600);
  }

  .challenges-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 40px;
    background: #ef7d00;
    margin: 0px !important;
    box-shadow: 0px 1px 2px var(--shadow-shade-05);
    border-radius: 5px;
    text-transform: uppercase;
  }

  .challenges-button:hover {
    box-shadow: inset 0px 0px 400px 110px var(--shadow-shade-2);
  }

  .link {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--default-background) !important;
    text-decoration: none;
  }
}

@media screen and (max-width: 900px) {
  .general-information {
    padding: 50px 20px 20px;
  }
}
