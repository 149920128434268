@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap);

.hackatons-card {
  padding-right: 50px;
}

.hackatons-card-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 311px;
  height: 600px;
  background: var(--default-background);
  border: 1px solid var(--text-color-200);
  box-shadow: 0px 2px 20px var(--shadow-shade-1);
  border-radius: 0px 0px 8px 8px;
}

.hackatons-card-item img {
  width: 100%;
  height: 168px;
}

.hackatons-card-body {
  text-align: center;
  padding: 0 10px;
}

.hackatons-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
}

.hackatons-card-body h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: var(--success-color-dark);
  height: auto;
}

.hackatons-card-body > p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--text-color-600);
  height: 200px;
  overflow-y: scroll;
}

.hackatons-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--warning-color-deep);
  box-shadow: 0px 1px 1px var(--shadow-shade-05);
  border-radius: 5px;
  width: 150px;
  height: 40px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--default-white) !important;
  text-decoration: none;
  align-self: center;
}

.hackatons-button:hover {
  box-shadow: inset 0px 0px 400px 110px var(--shadow-shade-2);
}
