@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap);

.closed-challenges {
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 50px;
    margin: 0 auto;
    max-width: 1100px;
    padding-top: 100px;
}

.closed-challenges h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    color: var(--primary-color-main);
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: transparent;
}
